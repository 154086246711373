import { Link } from "react-router-dom";
import Logo from "../Imagenes/Logo-pic.png";
import "./Menu.css";


export default function Menu(){
        return(
            <div id="navbar">
                <div className="linkContainer" id="logoContainer">
                    <Link to="/">
                       <img src={Logo} alt="" id="Logo"/>
                    </Link>
                </div>
                <div className="linkContainer">
                    <Link 
                        to="/servicios-matematicos"
                        className="link"
                    >
                        Servicios matemáticos
                    </Link>
                </div>
                <div className="linkContainer">
                    <Link 
                        to="/servicios-de-desarrollo"
                        className="link"
                    >
                        Servicios de desarrollo web
                    </Link>
                </div>
                <div className="linkContainer">
                    <Link 
                        to="/acerca-de"
                        className="link"
                    >
                        Acerca de
                    </Link>
                </div>
                <div className="linkContainer">
                    <Link 
                        to="/contacto"
                        className="link"
                        id="contactLink"
                    >
                        Contacto
                    </Link>
                </div>
            </div>
        );
}