import Menu from "../../Componentes/Menu.js";
import Finance from "../../Imagenes/finance.png";

export default function ModelosFinancieros(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={Finance} className="image" alt=""/>
            </div>
            <h1 className="title">Modelos financieros</h1>
            <p className="text">
                <b>Descripción: </b>
                Desarrollamos modelos financieros utilizando 
                Álgebra y Cálculo para análisis de inversiones, 
                proyecciones de ingresos y análisis de riesgos.
            </p>
            <p className="text">
                <b>Ejemplo: </b>
                Creamos un modelo financiero que utiliza Álgebra 
                para prever el rendimiento de una inversión a lo 
                largo del tiempo, considerando diversas variables financieras.
            </p>
           <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO x USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p> 
            <p className="text">
                <b>Precio: </b>
                Desde $1,200 o C$ 43,920 por proyecto. Pago disponible 
                en plazos de 3 a 6 meses.
            </p>
            <p className="priceText">
                <b>Tarifa horaria: </b>
                $49 o C$ 1,800 por hora.
            </p>
        </div>
    );
}