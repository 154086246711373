import Menu from "../../Componentes/Menu.js";
import Server from "../../Imagenes/server.png";
import "./Backend.css";

export default function Backend(){
    return(
       <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={Server} alt="" className="image" />
            </div>
            <article>
                <h1 className="title">Desarrollo backend o de servidores</h1>            
                <p className="text">
                    <b>Descripción: </b>
                    Este servicio se centra en construir la infraestructura que
                    impulsa las aplicaciones y sitios web, asegurando que sean 
                    seguros, eficientes y escalables.
                </p>
                <p className="text">
                    El backend es parte del sistema que maneja la lógica
                    del servidor, la gestión de bases de datos y la integración
                    con otros servicios, siendo fundamental para el correcto
                    functionamiento y rendimiento de cualquier aplicación web.
                    En Hausdorff nos dedicamos principalmente a crear y manejar 
                    la lógica del servidor, siendo la gestión de bases de datos 
                    un servicio que ofreceremos a futuro.
                </p>
                <p className="text">
                    <b>Tecnologías empleadas: </b>
                    NodeJS, ExpressJS, Git
                </p>
                <p className="text">
                    <b>Tipo de cambio de dólar: </b>
                    36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
                </p>
                <p className="text">
                    <b>Precio: </b>
                    Desde $2,400 o C$87,888 a $5,400 o C$197,748 por proyecto. Disponible sólo 
                    a plazos de 3 meses.
                </p>
                <p className="priceText">
                    <b>Tarifa horaria: </b>
                    Desde $24 o C$879 hasta $36 o C$1,318 por hora.
                </p>    
            </article>
       </div> 
    );
}