import Menu from "../Componentes/Menu.js";
import Whatsapp from "../Imagenes/whatsapp-icon.png";
import "./Contact.css";

export default function Contacto(){
    return(
        <div id="contacto">
            <Menu />
           <h1 className="title" id="mainTitle">Contactanos</h1>
           <div className="IconContainer">
                <div className="centered">
                    <img src={Whatsapp} alt="" className="whatsapp" />
                    <p>(+505) 81061595</p>
                </div>
           </div>
           <div className="EmailContainer">
                <div className="centered">
                    <p>
                        También escribinos al correo: valerietuckler@gmail.com
                    </p>
                    <p className="copyright">&copy; TODOS LOS DERECHOS RESERVADOS</p>
                </div>                
           </div>   
        </div>
    );
} 