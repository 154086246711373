import Menu from "../../Componentes/Menu.js";
import Electronics from "../../Imagenes/electronic-components.png";
import "./DiseñoElectronicos.css";

export default function DiseñoElctronicos(){
    return(
        <div className="TextContainer">
            <Menu />
            <h1 className="warning">SERVICIO NO DISPONIBLE</h1>
            <div className="imageContainer">
                <img src={Electronics} alt="" className="image"/>
            </div>
            <h1 className="title">Diseño de electronicos</h1>
            <p className="text">
                <b>Descripción: </b>
                Aplicamos Geometría Analítica y Lógica 
                en el diseño de Sistemas Electrónicos, 
                mejorando eficiencia y confiabilidad.
            </p>
            <p className="text">
                <b>Ejemplo: </b>
                Utilizamos Geometría Analítica 
                para diseñar circuitos electrónicos 
                que minimizan la interferencia y mejoran 
                la calidad de la señal en dispositivos electrónicos.
            </p>
            <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p>
            <p className="text">
                <b>Precio: </b>
                Desde $1,500 o C$54,930 por proyecto. Disponible a plazos de 3 a 6 meses.
            </p>
            <p className="priceText">
                <b>Tarifa horaria: </b>
                No disponible para este servicio.
            </p>
        </div>
    );
}