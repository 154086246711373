import Menu from "../../Componentes/Menu.js";
import Devops from "../../Imagenes/devops.png";

export default function SoftwareMatematico(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={Devops} alt="" className="image"/>
            </div>
            <h1 className="title">Desarrollo de software matemático</h1>
            <p className="text">
                <b>Descripción: </b>
                Creamos software expecializado que utiliza 
                algoritmos basados en Álgebra y Cálculo para 
                resolver problemas matemáticos específicos de la empresa.
            </p>
            <p className="text">
                <b>Ejemplo: </b>
                Desarrollamos una aplicación que utiliza 
                Álgebra para resolver ecuaciones complejas en 
                tiempo real, facilitando cálculos matemáticos avanzados.
            </p>
            <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p>
            <p className="text">
                <b>Precio: </b>
                Desde $1,200 o C$43,920 por proyecto. Disponible a plazos de 3 a 6 meses.
            </p>
            <p className="priceText">
                <b>Tarifa horaria: </b>
                $47 o C$1,723 por hora.
            </p>
        </div>
    );
}