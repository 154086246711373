import { Outlet } from "react-router-dom";
import Menu from "../Componentes/Menu.js";
import "./Inicio.css";

export default function Inicio(){
    return(
        <div className="TextContainer">
            <Menu />            
            <article className="Home">
                <h1 className="title" id="mainTitle">Bienvenido a Hausdorff</h1>
                <p className="text">
                    En Hausdorff, transformamos ideas complejas en 
                    soluciones elegantes y eficientes. Somos una empresa de servicios
                    matemáticos y de desarrollo de software de gama alta. Fundada y dirigida por
                    una estudiante de Matemáticas Aplicadas y desarrolladora web senior.
                    Nuestra misión es proporcionar soluciones personalizadas que cumplan
                    con cada una de las necesidades de nuestros clientes.    
                </p>
            </article>
            <article className="Home">
                <h1 className="title">Nuestros servicios</h1>
                <p className="text">
                    Ofrecemos una gama selecta de servicios diseñados para optimizar y 
                    transformar procesos empresariales.
                    <ol>
                        <li>
                            <b>Servicios matemáticos: </b>
                            desde la optimización de procesos hasta 
                            el desarrollo de modelos financieros y 
                            análisis avanzado de datos, nuestros 
                            servicios matemáticos están diseñados para resolver
                            los desafíos más complejos con precisión y eficiencia.
                        </li>
                        <li>
                           <b>Desarrollo de software: </b> 
                           especializados en frontend, backend y soluciones fullstack,
                           utilizamos tecnologías de vanguardia para crear software 
                           que no sólo es funcional, sino también estéticamente
                           impecable y altamente eficiente.
                        </li>
                    </ol>
                </p>
                <h3 className="subtitle">Compromiso con la excelencia</h3>
                <p className="text">
                    Cada proyecto que emprendemos es una oportunidad para mostrar 
                    nuestra meticulosa atención al detalle y nuestro 
                    compromiso inquebrantable con la excelencia. Nos 
                    enorgullecemos de trabajar de cerca con nuestros
                    clientes para entender sus necesidades únicas y
                    ofrcer soluciones que sean exclusivas como efectivas.
                </p>
                <h3 className="subtitle">Experiencia Hausdorff</h3>
                <p className="text">
                    En Hausdorff, no sólo resolvemos problemas; creamos soluciones únicas
                    que redefinen lo que es posible. Únite a nosotros y
                    descubrí una nueva dimensión de calidad y excelencia 
                    en servicios matemáticos y de desarrollo de software.
                </p>
            </article>
            <article className="Home">
                <h1 className="title">Pasos para tener una Experiencia Hausdorff</h1>
                <h3 className="subtitle clientLimitations">Limitación de servicios por cliente</h3>
                <p className="text">
                    Un cliente sólo puede contratar 1 servicio de la sección 
                    “Servicios matemáticos” y 1 servicio de la sección 
                    “Servicios de desarrollo web” simultáneamente. Es decir, 
                    un cliente sólo puede contratar 2 servicios a la vez. 
                    Esto es para asegurarnos que el proyecto esté bien definido y 
                    no hayan retrasos debido a una mala comunicación.
                </p>

                <h3 className="subtitle clientLimitations">Restricciones en ambas secciones de servicios</h3>
                <p className="text">
                    Si un cliente, por ejemplo, contrató un servicio de 
                    la Guía que está en la sección “Servicios Matemáticos”, 
                    es decir, una consulta por hora, no podrá contratar 
                    en ese mismo momento el desarrollo de un proyecto que está 
                    en esa misma sección. Lo mismo aplica para la sección 
                    “Servicios de Desarrollo Web”. 
                </p>
                <p className="text">
                    Al contratar un servicio de tarifa horaria, 
                    el cliente, si lo desea, deberá contratar un 
                    segundo servicio de tarifa horaria de cualquiera 
                    de las dos secciones, o quedarse simplemente con 
                    el servicio de tarifa horaria que inicialmente contrató.
                </p>

                <h3 className="subtitle">Proyectos y tarifas por hora</h3>
                <p className="text">
                    Las consultas o tarifas horarias cuentan 
                    como servicios completos. Esto es así ya que 
                    se tienen que agendar al igual que las tareas 
                    para proyectos completos y al incluirse dentro 
                    del cronograma pueden potencialmente retrasar 
                    el proyecto de otro cliente. Entonces, para 
                    evitar retrasos a otros proyectos y cumplir con 
                    todos nuestros usuarios, nos limitamos a brindarle 
                    al cliente 1 proyecto matemático junto a un proyecto 
                    de desarrollo de software, además de las reglas 
                    para las consultas a Hausdorff, detalladas en el 
                    título anterior. Cada proyecto consta de módulos 
                    que se entregarán de acuerdo al plazo de pago.
                </p>
                
                <h3 className="subtitle">Los proyectos y su modularidad</h3>
                <p className="text">
                    Al iniciar un nuevo proyecto con nosotros, se 
                    te mostrará cierta cantidad de módulos que juntos 
                    formarán tu proyecto final, sea este de matemáticas 
                    o de desarrollo web. Sin embargo, para garantizar nuestro 
                    flujo operativo, necesitaremos que vayas desbloqueando 
                    cada módulo a través de su pago correspondiente. 
                </p>
                <p className="text">
                    Para mostrarte un ejempo, el proyecto ABCD de 
                    la sección “Servicios de desarrollo de software” 
                    tiene 4 módulos y se deberá pagar en un plazo de 3 meses. 
                    Para desbloquear 1 módulo y medio necesitarás pagarle a Hausdorff 
                    el primer mes del plazo de 3 meses. Así, tenemos:
                    <ul>
                        <li>1 módulo y medio = primer cuota mensual</li>
                        <li>1 módulo y medio = segunda cuota mensual</li>
                        <li>1 módulo + mantenimiento = tercer cuota mensual</li>
                    </ul>
                </p>
                <p className="text">
                    En el caso del proyecto ABCD el mantenimiento durará 3 meses, 
                    mientras que si los plazos de pago se extendieran hasta los 6 meses, 
                    entonces el mantenimiento duraría 6 meses.
                </p>
                <p className="text">
                    Ahora, analicemos un proyecto de la sección 
                    “Servicios matemáticos”. El proyecto XYZ tiene 6 módulos 
                    y se deberá pagar en un plazo de 6 meses. Así, tenemos:
                    <ul>
                        <li>1 módulo = 1 cuota mensual</li>
                    </ul>
                </p>
                <p className="text">
                    El proyecto XYZ no incluirá mantenimiento de software, 
                    ya que no es un proyecto de software, sino que incluirá 3 meses 
                    de revisión de todos los cálculos entregados en los reportes 
                    matemáticos mensuales de todos los 6 meses.
                </p>
                <p className="text">
                    Cabe destacar que cada módulo de los proyectos 
                    en la sección de “Servicios matemáticos” incluye 
                    su reporte matemático donde se detalla  de una manera 
                    más formal y digitalizada las preguntas y problemas 
                    resueltos por Hausdorff.
                </p>

                <h3 className="subtitle">Dicho todo lo anterior, te desea lo mejor, Hausdorff.</h3>
            </article>
            

            {/*El elemento outlet siempre va al final de elementos principales*/}
            <Outlet />
        </div>
    );
}