import Menu from "../../Componentes/Menu.js";
import "./AnalisisDatos.css";
import DataScience from "../../Imagenes/data-science.png";

export default function AnalisisDatos(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={DataScience} alt="" className="image"/>
            </div>
            <h1 className="title">Análisis de datos avanzado</h1>
            <p className="text">
                <b>Descripción: </b>
                Utilizamos Lógica y Teoría de Conjuntos 
                para realizar análisis avanzado de datos, 
                identificar patrones y proporcionar insights valiosos.
            </p>
            <p className="text">
                <b>Ejemplo: </b>    
                Aplicamos Teoría de conjuntos para segmentar 
                datos de clientes, identificando grupos específicos 
                con comportamientos similares para estrategias de 
                Marketing más efectivas.
            </p> 
            <p className="text">
                <b>Tipo de cambio de dólar: </b>    
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p>
            <p className="text">
                <b>Precio: </b>    
                Desde $900 o C$32,958 por proyecto. Disponible sólo a plazos de 3 meses. 
            </p>           
            <p className="priceText">
                <b>Tarifa horaria: </b>
                $35 o C$1,300 por hora. 
            </p>
        </div>
    );
}