import Menu from "../../Componentes/Menu.js";
import frontend from "../../Imagenes/frontend.png";

export default function Frontend(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={frontend} alt="" className="image"/>
            </div>
            <h1 className="title">Desarrollo Frontend</h1>
            <p className="text">
                <b>Descripción: </b>
                En Hausdorff nos enfocamos en crear 
                experiencias de usuario excepcionales
                y visualmente atractivas a través de tecnologías avanzadas y
                diseño innovador.
            </p>
            <p className="text">
                El frontend es la parte del sitio web o aplicación con la que
                interactúan directamente los usuarios, por lo que su calidad y funcionalidad
                son fundamentales para el éxito de cualquier proyecto digital.
            </p>
            <p className="text">
                <b>Tecnologías a emplearse: </b>
                React, JavaScript, HTML
            </p>
            <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p>
            <p className="text">
                <b>Precio: </b>
                Desde $2,400 o C$87,888 a $4,500 o C$164, 790 por proyecto. Disponible sólo a plazos de 3 meses.
            </p>
            <p className="priceText">
                <b>Tarifa horaria: </b>
                Desde $20 o C$732 hasta $30 o C$1,099 por hora.
            </p>
        </div>
    );
}