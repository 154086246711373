import Menu from "../../Componentes/Menu.js";
import ComputerEngineer from "../../Imagenes/computer-engineer.png";

export default function MantenimientoSoftware(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={ComputerEngineer} alt="" className="image"/>
            </div>
            <h1 className="title">Mantenimiento continuo de software</h1>
            <p className="text">
                <b>Descripción: </b>
                Utilizamos Lógica y Teoría de Conjuntos para 
                mantener y mejorar continuamente la eficiencia 
                de sistemas y aplicaciones escritas en JavaScript únicamente.
            </p>
            <p className="text">
                <b>Ejemplo: </b>
                Aplicamos Lógica para identificar y 
                corregir posibles fallos en el código 
                asegurando un funcionamiento continuo y 
                eficiente de la aplicación.
            </p>
            <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p>
            <p className="text">
                <b>Precio: </b>
                Desde $600 o C$21,972 por proyecto. Únicamente pago inmediato.
            </p>
            <p className="priceText">
                <b>Tarifa horaria: </b>
                $54 o C$2,00 por hora.
            </p>
        </div>
    );
}