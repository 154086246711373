import { Outlet, Link } from "react-router-dom";
import Menu from "../Componentes/Menu.js";
import "./Index-Desarrollo.css";

export default function IndexDesarrollo(){
    return(
        <div>
            <Menu />
            <h1 className="title" id="web">Servicios de desarrollo web</h1>

            <div className="DevContainer">
                <Link to="/desarrollo-frontend" className="devLink">
                    Desarrollo de proyectos frontend
                </Link>
            </div>
            <div className="DevContainer">
                <Link to="/desarrollo-backend" className="devLink">
                    Desarrollo de proyectos backend
                </Link>
            </div>
            <div className="DevContainer">
                <Link to="/animaciones-avanzadas" className="devLink">
                    Inserción de animaciones avanzadas
                </Link>
            </div>
            <div className="DevContainer">
                <Link to="/desarrollo-fullstack" className="devLink">
                    Desarrollo fullstack o de proyectos completos
                </Link>
            </div>

            {/*El elemento outlet siempre va al final de elementos principales*/}
            <Outlet />
        </div>
    );
}