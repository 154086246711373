import Menu from "../../Componentes/Menu.js";
import Inventory from "../../Imagenes/inventory.png";

export default function OptimizacionInventarios(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={Inventory} alt="" className="image"/>
            </div>
            <h1 className="title">Optimización de inventarios</h1>
            <p className="text">
                <b>Descripción: </b>
                Empleamos técnicas de Álgebra y Cálculo 
                para optimizar niveles de inventario, 
                reduciendo costos y maximizando eficiencia.
            </p>
            <p className="text">
                <b>Ejemplo: </b>
                Utilizamos Álgebra para calcular los 
                puntos óptimos de pedido y Cálculo de derivadas 
                para determinar las tasas de reorden, minimizando los costos 
                de almacenamiento y agotamiento de existencias.
            </p>
            <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p>
            <p className="text">
                <b>Precio: </b>
                Desde $1,000 o C$36,620 por proyecto. Disponible sólo a plazos de 3 meses.
            </p>
            <p className="priceText">
                <b>Tarifa horaria: </b>
                $40 o C$1,465 por hora.
            </p>
        </div>
    );
}