import { Outlet, Link } from "react-router-dom";
import Menu from "../Componentes/Menu.js";
import "./Index-Matematicas.css";

export default function IndexMatematicas(){
    return(
        <div>
            <Menu />
            <h1 className="title" id="math">Servicios Matemáticos</h1>
            <div className="optionContainer">
                <Link to="/optimizacion-de-procesos" className="mathlink">
                    Optimización de procesos empresariales
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/modelos-financieros" className="mathlink">
                    Desarrollo de modelos financieros
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/analisis-de-datos" className="mathlink">
                    Análisis de datos avanzado
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/diseño-de-electronicos" className="mathlink">
                    Diseño de dispositivos electrónicos
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/optimizacion-de-inventarios" className="mathlink">
                    Optimización de inventarios
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/desarrollo-software-matematico" className="mathlink">
                    Desarrollo de software matemático
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/mantenimiento-de-software" className="mathlink">
                    Mantenimiento de software
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/desarrollo-herramientas-automatizacion" className="mathlink">
                    Desarrollo de herramientas matemáticas para automatización
                </Link>
            </div>
            <div className="optionContainer">
                <Link to="/guia-para-consultas" className="mathlink">
                    Guía para consultas horarias
                </Link>
            </div>

            {/*El elemento outlet siempre va al final de elementos principales*/}
            <Outlet />
        </div>
    );
}