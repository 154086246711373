import { Outlet } from "react-router-dom";
import Menu from "../Componentes/Menu.js";
import "./AcercaDe.css";

export default function AcercaDe(){
    return(
        <div className="TextContainer">
            <Menu />
            <article id="aboutContent">
                <h1 className="title">Acerca de Hausdorff</h1>
                <p className="text">
                    Hausdorff es una firma de servicios matemáticos y de desarrollo de software
                    que se distingue por su exclusividad y alta gama. Fundada y dirigida por una 
                    estudiante de Matemáticas Aplicadas y desarrolladora web senior, 
                    nos especializamos en ofrecer
                    soluciones altamente personalizadas y de calidad superior, adaptadas a las 
                    necesidades específicas de cada cliente. Nuestro enfoque se basa en la 
                    atención meticulosa a los detalles y el compromiso con la excelencia.
                </p>
                <p className="text">
                    Ofrecemos una gama selecta de servicios diseñados para optimizar y 
                    transformar procesos empresariales, desde las matemáticas de nivel
                    universitario, hasta el desarrollo frontend y backend,
                    incluyendo proyectos completos de software o fullstack. Cada proyecto que 
                    emprendemos es tratado con un enfoque exclusivo, garantizando que 
                    nuestras soluciones sean únicas y adecuadas para cada cliente.
                </p>
                <p className="text">
                    Nos enorgullece utilizar tecnologías y técnicas avanzadas en todas nuestras
                    ofertas, lo que nos permite brindar una experiencia incomparable
                    y soluciones de gama alta. Entendemos que nuestros clientes no sólo buscan 
                    resultados excepcionales, sino también una experiencia de colaboración única
                    y de alto nivel.
                </p>
            </article>

            {/*El elemento outlet siempre va al final de elementos principales*/}
            <Outlet />
        </div>
    );
}