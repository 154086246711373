import Menu from "../../Componentes/Menu.js";
import Tools from "../../Imagenes/mechanical-tools.png";

export default function HerramientasAutomatizacion(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={Tools} alt="" className="image"/>
            </div>
            <h1 className="title">Desarrollo de herramientas matemáticas para automatización</h1>
            <p className="text">
                <b>Descripción: </b>
                Implementamos soluciones automatizadas 
                utilizando Álgebra y Lógica 
                para simplificar procesos operativos.
            </p>
            <p className="text">
                <b>Ejemplo: </b>
                Creamos un sistema de automatización
                que utiliza Álgebra para calcular 
                automáticamente métricas clave y Lógica 
                para tomar decisiones basadas en los resultados.
            </p>
            <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p>
            <p className="text">
                <b>Precio: </b>
                Desde $700 o C$25,634 por proyecto. Únicamente pago inmediato.
            </p>
            <p className="priceText">
                <b>Tarifa horaria: </b>
                $40 o C$1,465 por hora.
            </p>
        </div>
    );
}