import Menu from "../../Componentes/Menu.js";
import Robot from "../../Imagenes/industrial-robot.png";
import "./OptimizacionProcesos.css";

export default function OptimizacionProcesos(){
    return(
       <div className="TextContainer">
        <Menu />            
            <div className="imageContainer">
                <img src={Robot} alt="" className="image"/>
            </div>
            <h1 className="title">Optimización de procesos empresariales</h1> 
            <p className="text">
                <b>Descripción: </b>
                Aplicamos técnicas avanzadas de Álgebra
                y Cálculo de derivadas para identificar 
                áreas de mejora en los procesos empresariales, 
                aumentando eficiencia y reduciendo costos. 
            </p>
            <p className="text">
                <b>Ejemplo: </b>    
                Analizamos el flujo de producción y utilizamos
                Cálculo para optimizar los tiempos de cada etapa, 
                reduciendo los tiempos de espera y mejorando la eficiencia general.
            </p>
            <p className="text">
                <b>Tipo de cambio de dólar: </b>
                36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
            </p> 
            <p className="text">
                <b>Precio: </b>
                Desde $800 o C$29,300 por proyecto. Disponible sólo a plazos de 3 meses.
            </p>            
            <p className="priceText text">
                <b>Tarifa horaria: </b>    
                $45 o C$1,647 por hora.
            </p>          
       </div> 
    );
}