import Menu from "../../Componentes/Menu.js";
import MERN from "../../Imagenes/mern-stack.png";

export default function Fullstack(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={MERN} alt="" className="image"/>
            </div>
            <article>
                <h1 className="title">Desarrollo fullstack o proyectos completos personalizados</h1>
                <p className="text">
                    <b>Descripción: </b>    
                    Este apartado representa un servicio integral que abarca todas
                    las etapas del desarrollo de software, desde la conceptualización,
                    hasta la implementación y mantenimiento. Este servicio está diseñado 
                    para empresas que buscan una solución completa y personalizada para 
                    sus necesidades digitales. Esto incluye el frontend (la interfaz de usuario)
                    y el backend (servidor y lógica de negocio) de una aplicación o sitio web.
                </p> 
                <p className="text">
                    <b>Tecnologías utilizadas: </b>
                    <p className="text">FRONTEND</p>
                    <ul>
                        <li>
                            React: Biblioteca de JavaScript para construir
                            interfaces de usuario interactivas y dinámicas.
                        </li>
                        <li>
                            HTML5 y CSS3: Para la estructura y diseño 
                            visual de las páginas web.
                        </li>
                        <li>
                            JavaScript: Lenguaje de programación para
                            agregar interactividad y dinamismo.
                        </li>
                        <li>
                            GSAP: Para animaciones avanzadas y efectos visuales.
                        </li>
                    </ul>
                    <p className="text">BACKEND</p>
                    <ul>
                        <li>
                            NodeJS: Entorno de ejecución de JavaScript del lado
                            del servidor, ideal para construir aplicaciones escalables
                            y de alto rendimiento.
                        </li>
                        <li>
                            Express: Framework de NodeJS para crear servidires web y APIs RESTful.
                        </li>
                        <li>
                            Bases de datos: (MongoDB) - PRÓXIMAMENTE.
                        </li>
                    </ul>
                </p>
                <p className="text">
                    <b>Tipo de cambio de dólar: </b>
                    36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
                </p> 
                <p className="text">
                    <b>Precio: </b>    
                    Desde $3,500 o C$128,170 hasta $6,000 o C$219,720 por proyecto. Disponible a 
                    plazos de 3 a 6 meses.
                </p> 
                <p className="text">
                    Hausdorff se especializa en ofrecer soluciones fullstack que son robustas, seguras 
                    y escalables. Con enfoque en la calidad y la innovación, nos esforzamos
                    por cumplir con las expectativas de los clientes, entregando productos que 
                    no sólo cumplen con sus necesidades técnicas, sino que también añaden valor a 
                    su negocio.
                </p>             
            </article>
        </div>
    );
}