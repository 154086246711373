import Menu from "../../Componentes/Menu.js";
import Earth from "../../Video/earth.mp4";

export default function AnimacionesAvanzadas(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
               <video src={Earth} className="image" controls="controls" autoplay="true" />
            </div>
            <article>
                <h1 className="title">Inserción de animaciones avanzadas</h1>
                <p className="text">
                    <b>Descripción: </b>
                    Este servicio es una especialización que se enfoca en a creación
                    de experiencias interactivas y visualmente impactantes a través de 
                    la implementación de animaciones en la web.
                </p>
                <p className="text">
                    Este servicio se destaca por su capacidad para transformar interfaces 
                    de usuario ordinarias en experiencias dinámicas y atractivas,
                    aumentando el compromiso del usuario y diferenciando a las marcas de
                    sus competidores.
                </p>
                <p className="text">
                    <b>Tecnologías utilizadas: </b>
                    GSAP Animation Products y CSS.
                </p>
                <p className="text">
                    <b>Tipo de cambio de dólar: </b>
                    36.62 (NIO X USD). Tipo de cambio oficial del Banco Central de Nicaragua.
                </p>
                <p className="text">
                    <b>Precio: </b>
                    Desde $2,100 o C$76,902 hasta $4,500 o C$164,790 por proyecto.
                    Disponible sólo a plazos de 3 meses.
                </p>
            </article>
        </div>
    );
}