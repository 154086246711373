import Menu from "../../Componentes/Menu.js";
import Consulting from "../../Imagenes/consulting.jpg"

export default function GuiaConsultas(){
    return(
        <div className="TextContainer">
            <Menu />
            <div className="imageContainer">
                <img src={Consulting} alt="" className="image"/>
            </div>
            <h1 className="title">Consultoría Matemática</h1>
            <h2 className="subtitle">(Temas sugeridos para pagos por hora)</h2>
            <article>                
                <p className="text">
                    ÁLGEBRA
                    <ol>
                        <li>
                            <b>Finanzas y contabilidad:</b> Creamos y ajustamos presupuestos
                            usando ecuaciones para prever ingresos y gastos futuros.        
                        </li>
                        <li>
                            <b>Análisis financiero: </b> Usamos fórmulas para calcular ratios 
                            financieros que ayudan a evaluar la salud financiera de una empresa.
                        </li>
                        <li>
                            <b>Determinación de precios de productos: </b> Utilizamos
                            ecuaciones para considerar el precio de venta usando costos, 
                            márgenes de ganancia y competidores.
                        </li>
                        <li>
                            <b>Análisis de punto de equilibrio: </b> Determinar
                            el punto en el que los ingresos igualan a los costos 
                            totales, permitiendo a las empresas planificar la producción y
                            las ventas.
                        </li>
                        <li>
                            <b>Previsión de ventas: </b> Usamos modelos algebraicos para
                            prever las ventas futuras basándonos en datos históricos.
                        </li>
                        <li>
                            <b>Optimización de inventarios: </b> Determinar la cantidad óptima 
                            de inventario para minimizar costos y maximizar la eficiencia.
                        </li>
                        <li>
                            <b>Segmentación de mercado: </b> Usamos ecuaciones para 
                            analizar y segmentar mercados basándonos en diversos sectores
                            demográficos y de comportamiento.
                        </li>
                        <li>
                           <b>Eficiencia de campañas publicitarias: </b> 
                           Evaluamos el retorno de la inversión en campañas publicitarias 
                           usando fórmulas para medir su impacto y eficiencia.
                        </li>
                        <li>
                            <b>Planificación y programación: </b> A través de ecuaciones creamos
                            cronogramas funcionales y presupuestos detallados de proyectos,
                            asegurando que se completen a tiempo y dentro del presupuesto.
                        </li>
                    </ol>
                </p>    
            </article>
            <article>
                <p className="text">
                    LÓGICA Y TEORÍA DE CONJUNTOS
                    <ol>
                        <li>
                            <b>Optimización de procesos: </b>Se usa Lógica para
                            identificar cuellos de botella y optimizar procesos de 
                            producción y operaciones.
                        </li>
                        <li>
                            <b>Algoritmos: </b>
                            La Lógica es fundamental para el diseño y la implementación 
                            de algoritmos eficientes que resuelvan problemas específicos
                            del negocio.
                        </li>
                        <li>
                            <b>Evaluación de riesgos: </b>
                            Usamos principios Lógicos para identificar y evaluar riesgos 
                            potenciales y desarrollar estrategias de mitigación.
                        </li>
                        <li>
                            <b>Negociaciones: </b>
                            Usar la Lógica para analizar las ofertas y las contrapropuestas,
                            ayudando a tomar decisiones estratégicas durante las negociaciones.
                        </li>
                        <li>
                            <b>Clasificación de clientes: </b>
                            Usamos la Teoría de Conjuntos para segmentar mercados en
                            diferentes grupos de clientes basádonos en características 
                            comunes, lo que permite personalizar estrategias de marketing
                            y ventas.
                        </li>
                    </ol>
                </p>
            </article>

        </div>
    );
}